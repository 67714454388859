import * as React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { theme } from "./colors";

const ItemSpace = styled.div`
  width: calc((100% / 3) - 1rem);
  display: flex;
  margin-bottom: 1.2rem;
	@media (max-width: 1000px) {
    width: calc((100% / 2) - 1rem);
  }
	@media (max-width: 770px) {
    width: 100%;
  }
`;
const ItemWrapper = styled(Link)`
  display: flex;
  width: 100%;
  border: 0.4rem solid rgba(255, 255, 255, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  position: relative;

  :hover {
    img {
      transform:  scale(1.1);
      transition: 300ms;
    }
    div {
      color: ${theme.action};
    }
  }
`;
const ItemImage = styled.img`
  width: 100%;
  display: block;
  transition: 300ms;
  z-index: 10;
`;
const ItemDetails = styled.section`
  width: 100%;
  background-color: ${theme.mainBackground};
  color: ${theme.text};
  z-index: 20;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 4rem;
`;
const ItemTitle = styled.div`
  padding: 0.4rem 1rem;
  font-size: 1.4rem;
  text-decoration: none;
  text-align: center;
  display: block;
  width: 100%;
`;

const Item = ({ title, img,link,blog }) => {
  return (
    <ItemSpace>
      <ItemWrapper to={`/${blog ? 'blog' : 'projects'}/${link}`}>
        <ItemImage src={img} />
        <ItemDetails>
          <ItemTitle>{title}</ItemTitle>
        </ItemDetails>
      </ItemWrapper>
    </ItemSpace>
  );
};

export default Item;
