import * as React from "react"
import styled from "styled-components"
import { theme } from "../utilis/colors"


const HelloMsgWrapper = styled.div`
	padding: 5rem 4rem 9rem 4rem;
	width:100%;
	color:${theme.text};
	font-size:3.2rem;
	text-align:center;
	font-weight:100;
	@media (max-width: 800px) {
    font-size:2.4rem;
  }
	@media (max-width: 400px) {
    font-size:1.8rem;
		padding: 5rem 1rem 8rem 1rem;
  }
`
const BoldFont = styled.span`
	font-weight:bold;
`
const SubHello = styled.div`
	font-size:2.4rem;
	padding-bottom:0.4rem;
	font-weight: 400;
	@media (max-width: 800px) {
    font-size:1.8rem;
  }
	@media (max-width: 400px) {
    font-size:1.4rem;
  }
`
const Hello = () => {
	return(
		<HelloMsgWrapper>
			<SubHello>Hello</SubHello>
			My name is <BoldFont>Łukasz Stasiak</BoldFont><br/>
			I Am <BoldFont>Frontend Developer</BoldFont>
		</HelloMsgWrapper>
	)
}

export default Hello