import * as React from "react";
import Section from "../utilis/Section";

import Item from "../utilis/Item";
import { ItemsArea, MainText } from "../utilis/common";
import MoreButton from "../utilis/MoreButton";
import { theme } from "../utilis/colors";

const BlogSection = ({ posts }) => {
  return (
    <Section bgColor="#163C54" textColor={theme.text} title="My Blog">
      <MainText>
        Sometimes I describe my projects and thoughts on my blog. I encourage
        you to read the content I create. At the moment, all posts are in Polish
      </MainText>
      <ItemsArea>
        {posts.edges.map((item) => (
          <Item
            key={item.node.id}
            title={item.node.title}
            img={item.node.featuredImage.node.sourceUrl}
            link={item.node.slug}
            blog
          />
        ))}
      </ItemsArea>
      <MoreButton link="/blog" />
    </Section>
  );
};

export default BlogSection;
