import * as React from "react"
import styled from "styled-components"
import bg from '../../assets/images/lf_bg.jpg';
import Menu from './Menu';
import Hello from './Hello';


const HeaderWrapper = styled.div`
	width:100%;
	background-color:#020202;
	background-image: url(${bg});
	background-size: cover;
	background-position: center;

`
const Header = () => {
	return(
		<HeaderWrapper>
			<Menu/>
			<Hello/>
		</HeaderWrapper>
	)
}

export default Header