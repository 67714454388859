import * as React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import AboutMe from "../components/home/AboutMe";
import BlogSection from "../components/home/BlogSection";
import Header from "../components/header/Header";
import ProjectsSection from "../components/home/ProjectsSection";
import Layout from "../components/utilis/Layout";
import { Main } from "../components/utilis/common";
import Footer from "../components/utilis/Footer";
import flavicon from "../assets/icons/lf_flav.png";

export const query = graphql`
  query {
    blogPost: allWpPost(
      sort: { order: DESC, fields: [date] }
      filter: {
        categories: { nodes: { elemMatch: { parentDatabaseId: { eq: 185 } } } }
      }
      limit: 6
    ) {
      edges {
        node {
          title
          id
          date(formatString: "DD-MM-YYYY")
          slug
          featuredImage {
            node {
              sourceUrl
            }
          }
          categories {
            nodes {
              name
            }
          }
        }
      }
    }
    projects: allWpPost(
      sort: { order: DESC, fields: [date] }
      filter: {
        categories: { nodes: { elemMatch: { name: { eq: "Projects" } } } }
      }
      limit: 3
    ) {
      edges {
        node {
          title
          id
          date(formatString: "DD-MM-YYYY")
          slug
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    }
  }
`;
const IndexPage = ({ data }) => {
  const { blogPost, projects } = data;
  return (
    <Layout>
      <Main>
        <Helmet>
          <meta charSet="utf-8" />
          <title>lensflare.pl</title>
          <link rel="canonical" href="https://lensflare.pl/" />
          <link rel="icon" href={flavicon} sizes="192x192" />{" "}
        </Helmet>
        <Header />
        <AboutMe />
        <ProjectsSection projects={projects} />
        <BlogSection posts={blogPost} />
        <Footer />
      </Main>
    </Layout>
  );
};

export default IndexPage;
