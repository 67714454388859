import * as React from "react";
import { Link } from "gatsby"
import styled from "styled-components";
import { theme } from "./colors";

const ButtonWrapper = styled.div`
	width:100%;
	display: flex;
	padding: 2.4rem;
	align-items: center;
	justify-content: center;
`
const ButtonAction = styled(Link)`
	text-decoration: none;
	color:${theme.text};
	font-size:1.4rem;
	display: flex;
	padding:0.4rem 1.2rem;
	transition: 300ms;
	border-radius: 0.2rem;
	:hover{
		background-color: rgba(255,255,255,0.1);
	}
`

const MoreButton = ({link})=> {
	return(
		<ButtonWrapper>
			<ButtonAction to={`${link}`}>Show all</ButtonAction>
		</ButtonWrapper>
	)
}

export default MoreButton