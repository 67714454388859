import * as React from "react";
import Section from "../utilis/Section";
import Item from "../utilis/Item";
import { ItemsArea, MainText } from "../utilis/common";
import MoreButton from "../utilis/MoreButton";
import { theme } from "../utilis/colors";


const ProjectsSection = ({ projects }) => {
  return (
    <Section bgColor="#14213d" textColor={theme.text} title="My Projects">
      <MainText>
        My additional projects. Among them you can find fragments of interfaces,
        concepts and entire fully functional apps.
      </MainText>
      <ItemsArea>
        {projects.edges.map((item) => (
          <Item
            key={item.node.id}
            title={item.node.title}
            img={item.node.featuredImage.node.sourceUrl}
            link={item.node.slug}
          />
        ))}
      </ItemsArea>
      <MoreButton link="/projects" />
    </Section>
  );
};

export default ProjectsSection;
